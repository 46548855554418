import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import wave from "@public/wave.svg";
import waveous_logo_word from "@public/waveous_logo_word.svg";
import { Button } from "@web/components/ui/button";
import { LoadingSpinner } from "@web/components/ui/loading-spinner";
import { cn } from "@web/lib/utils";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export default function Index() {
    const router = useRouter();

    const [loggingIn, setLoggingIn] = useState(false);

    useEffect(() => {
        if (router.isReady) {
            if (router.query.error != undefined) {
                toast.error("Error during logging in", {
                    duration: 5000,
                    style: {
                        background: "#333",
                        color: "#fff",
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady]);

    const onLogin = () => {
        setLoggingIn(true);
        // timeout to fix issue with width not animating on phone
        setTimeout(() => {
            router.push(
                `${process.env.NEXT_PUBLIC_API_URL}/auth/spotify/authorize?callbackUrl=${location.origin}/auth/callback`,
            );
        }, 350);
    };

    return (
        <>
            <div className="shadow-inner-vignette fixed flex h-dvh w-dvw items-center justify-center text-center shadow-black">
                <Head>
                    <title>Waveous</title>
                </Head>
                <div className="flex flex-col items-center space-y-6 p-8">
                    <Image
                        // className={styles.title}
                        className="w-full"
                        src={waveous_logo_word}
                        alt="Waveous Logo"
                        sizes="100vw"
                    />
                    <p>log in to start tracking spotify listening statistics and be notified of new releases</p>
                    <Button
                        variant="outline"
                        onClick={onLogin}
                        className={cn(
                            "!mt-10 flex border-[1px] border-white px-6 py-7" +
                                " [box-shadow:_0_0px_15px_hsla(0,0%,100%,0.2)] hover:!border-green-600" +
                                " transition-all duration-300 hover:bg-green-600" +
                                " group w-[254px] justify-start",
                            loggingIn ? "w-[136px] border-green-600 bg-green-600" : null,
                        )}
                        // className="w-[254px] drop-shadow-[0_0px_10px_hsla(0,0%,100%,0.2)] transition-all
                        // duration-300 [-webkit-appearance:_none;]"
                    >
                        <FontAwesomeIcon
                            icon={faSpotify}
                            size="2x"
                            className={cn(
                                "text-green-500 transition-all duration-300 group-hover:text-white",
                                loggingIn ? "text-white" : null,
                            )}
                        />
                        {loggingIn ? (
                            <LoadingSpinner className="ml-auto" size={28} />
                        ) : (
                            <span className="ml-auto text-lg text-white">Log in with Spotify</span>
                        )}
                    </Button>
                </div>
            </div>
            <Image
                // className={styles.splash}
                className="!fixed -z-10 translate-y-[200px] object-cover opacity-50"
                src={wave}
                alt="Splash"
                fill
                priority
            />
        </>
    );
}
